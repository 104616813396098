import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../stores/mainContext';
import { getOptionNameByValue } from '../utils/findOptionByValue'

const AutocompleteRuns = props => {
  const { mainContext } = useContext(MainContext);

  const getOptions = () => {
    var options = []
    let definedOptions = mainContext.calCalc[props.varName]

    if (definedOptions && definedOptions['Run']) {
      options = definedOptions['Run'].map(item => ({
        name: `${new Date(item[1]).getDate()} ${new Date(item[1]).toLocaleString('default', { month: 'short' })} ${new Date(item[1]).getFullYear()} - ${item[2]} --- ${item[3]}`,
        value: item[0]
      }))
    } else {
      options = mainContext.allRuns.map(item => ({
        name: `${new Date(item.run_ts).getDate()} ${new Date(item.run_ts).toLocaleString('default', { month: 'short' })} ${new Date(item.run_ts).getFullYear()} - ${item.description
          } --- ${item?.wheel_circumference_dict.find(item2 => item2.id === item?.wheel_circumference_index)?.value}`,
        value: item.id
      }))
    }
    return options
  }

  const [runOptions] = React.useState(getOptions());
  const [openRunAutocomplete, setOpenRunAutocomplete] = React.useState(false);
  const [autoGroupState, setautoGroupState] = React.useState(props.value ? { name: (getOptionNameByValue(runOptions, props.value)), value: props.value } : null);

  return (
    <Autocomplete
      id="asynchronous-select2"
      /* className={`${classes.input} ${errorClass}`} */
      open={openRunAutocomplete}
      value={autoGroupState}
      //error={props.error}
      onChange={(event, newValue) => {
        //console.log('yes', newValue.value);
        props.onUpdate(newValue.value);
        setautoGroupState(newValue);
      }}
      onOpen={() => {
        setOpenRunAutocomplete(true);
      }}
      onClose={() => {
        setOpenRunAutocomplete(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => (option.name ? option.name : '')}
      options={runOptions}
      // loading={loading}
      renderInput={props => (
        <TextField
          //inputRef={inputEl}
          {...props}
          label="Defined by Run *"
          name="brand"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <>
                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                {props.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
export default AutocompleteRuns;
