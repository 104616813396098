import React, { useEffect, useState, useContext } from 'react';
import { Button, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import axios from '../utils/axios';
import MainContext from '../stores/mainContext';
import { Check } from '../icons/Check';
import createPlotlyComponent from 'react-plotlyjs';
//See the list of possible plotly bundles at https://github.com/plotly/plotly.js/blob/master/dist/README.md#partial-bundles or roll your own
import Plotly from 'plotly.js-cartesian-dist';
const PlotlyComponent = createPlotlyComponent(Plotly);

const Plot = () => {
  const { mainContext, dispatch } = useContext(MainContext);
  const [xAxis, setxAxis] = useState([]);
  const [xAxisVal, setxAxisVal] = useState(mainContext.plotVars.xAxis);
  const [yAxis, setyAxis] = useState([]);
  const [yAxisVal, setyAxisVal] = useState(mainContext.plotVars.yAxis);
  const [dataTypes, setdataTypes] = useState([]);
  const [dataTypesVal, setdataTypesVal] = useState(mainContext.plotVars.dataType);
  const [plotData, setplotData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        //const response = await axios.post('/run-plot/', JSON.stringify(mainContext.editedRuns.map(item => item.run_id)));
        //const response = await axios.post('/run-plot/', requestBody);
        const response = await axios.get('/run-plot/');
        console.log(response);
        setxAxis(response.data.channels);
        //setxAxisVal(response.data.channels[0]);
        setyAxis(response.data.channels);
        //setyAxisVal(response.data.channels[0]);
        setdataTypes(response.data['data-types']);
        //setdataTypesVal(response.data['data-types'][0]);
      } catch (e) {
        throw e;
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.editedRuns]);

  const goPlotting = async () => {
    dispatch({ type: 'SET_PLOT_VARS', payload: { xAxis: xAxisVal, yAxis: yAxisVal, dataType: dataTypesVal } });
    /*     const requestBody = {
      'x-axis': 'Start',
      'y-axis': 'CdaStd',
      'data-type': 'all',
      'run-ids': ['e1c836d8-3c6e-4340-b95a-bdf6d62a2549']
    }; */
    const requestBody = {
      'x-axis': xAxisVal,
      'y-axis': yAxisVal,
      'data-type': dataTypesVal,
      run_ids: JSON.stringify(mainContext.editedRuns.map(item => item.run_id))
    };
    try {
      const response = await axios.post('/run-plot/', requestBody);
      console.log(response);
      setplotData(response.data);
    } catch (e) {
      throw e;
    }
  };
  return (
    <div className="viewPlot">
      <div className="headline">
        <div className="line">
          <h2>View Plot</h2>
        </div>
      </div>
      <div className="plotVars">
        <div className="quarter">
          <FormControl className="select">
            <InputLabel shrink id="select-four">
              X&nbsp;Axis
            </InputLabel>
            <Select
              labelId="select-one"
              id={`x_axis`}
              value={xAxisVal}
              fullWidth
              onChange={ev => {
                if (ev.target.value !== xAxisVal) {
                  setxAxisVal(ev.target.value);
                }
              }}
            >
              {xAxis.map((item, index) => {
                return (
                  <MenuItem key={`xAxis_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="quarter">
          <FormControl className="select">
            <InputLabel shrink id="select-four">
              Y&nbsp;Axis
            </InputLabel>
            <Select
              labelId="select-one"
              id={`x_axis`}
              value={yAxisVal}
              fullWidth
              onChange={ev => {
                if (ev.target.value !== yAxisVal) {
                  setyAxisVal(ev.target.value);
                }
              }}
            >
              {yAxis.map((item, index) => {
                return (
                  <MenuItem key={`yAxis_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="quarter">
          <FormControl className="select">
            <InputLabel shrink id="select-four">
              Data Type
            </InputLabel>
            <Select
              labelId="select-one"
              id={`x_axis`}
              value={dataTypesVal}
              fullWidth
              onChange={ev => {
                if (ev.target.value !== dataTypesVal) {
                  setdataTypesVal(ev.target.value);
                }
              }}
            >
              {dataTypes.map((item, index) => {
                return (
                  <MenuItem key={`dataTypes_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="quarter">
          <br />
          <Button variant="outlined" className="classicButton" startIcon={<Check />} color="primary" onClick={goPlotting}>
            Apply
          </Button>
        </div>
      </div>
      <div className="plotItself">{plotData && <PlotlyComponent className="whatever" data={plotData} />}</div>
      {/* layout={layout} config={config} */}
      {/* <div className="plotItself">{plotData && JSON.stringify(plotData)}</div> */}
    </div>
  );
};
export default Plot;
