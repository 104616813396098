import React, { useContext, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../stores/mainContext';
import { getOptionNameByValue } from '../utils/findOptionByValue'

const AutocompleteGroups = props => {
  //console.log('AutocompleteGroups props', props);
  const { mainContext } = useContext(MainContext);
  //console.log(mainContext);

  const getOptions = () => {
    var options = []
    let definedOptions = mainContext.calCalc[props.varName]

    if (definedOptions && definedOptions['Group']) {
      options = definedOptions['Group'].map(item => ({
        name: `${item[2]} - ${item[3]}`,
        value: item[0]
      }))
    } else {
      options = mainContext.allGroups.map(item => ({
        name: `${item.description} - ${item[props.varName + '_calc']}`,
        value: item.id
      }))
    }
    return options
  }


  useEffect(() => {
    setGroupOptions(getOptions())
  }, [mainContext.calCalc]);

  const [groupOptions, setGroupOptions] = React.useState(getOptions());
  const [openGroupAutocomplete, setOpenGroupAutocomplete] = React.useState(false);
  const [autoGroupState, setautoGroupState] = React.useState(props.value ? { name: (getOptionNameByValue(groupOptions, props.value)), value: props.value } : null);
  //console.log(props.varName + '_calc');

  //console.log(props)

  return (
    <Autocomplete
      id="asynchronous-select"
      /* className={`${classes.input} ${errorClass}`} */
      open={openGroupAutocomplete}
      value={autoGroupState}
      //error={props.error}
      onChange={(event, newValue) => {
        //console.log('yes', newValue.value);
        props.onUpdate(newValue.value);
        setautoGroupState(newValue);
      }}
      onOpen={() => {
        setOpenGroupAutocomplete(true);
      }}
      onClose={() => {
        setOpenGroupAutocomplete(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => (option.name ? option.name : '')}
      options={groupOptions}
      // loading={loading}
      renderInput={props => (
        <TextField
          //inputRef={inputEl}
          {...props}
          label="Defined by Group *"
          name="brand"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <>
                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                {props.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
export default AutocompleteGroups;
