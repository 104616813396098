import React, { useState, useEffect } from 'react';
import { TextField, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import AutocompleteRuns from '../components/AutocompleteRuns';
import AutocompleteGroups from '../components/AutocompleteGroups';
import { KEEP_UNCHANGED } from '../const'

const CalibrationVar = props => {
  const { label, index, dictionary, onUpdate, onReset, varName, units } = props;
  //console.log(index, dictionary);
  //console.log('%c index ' + index, 'background: #222; color: #ffFF99');
  let paramValueInit = index === '' ? '' : index === KEEP_UNCHANGED ? KEEP_UNCHANGED : dictionary.find(item => item.id === index) ? dictionary.find(item => item.id === index).value : null;

  /*
  if (varName == "cp_corr") {
    console.log('%c index ' + index, 'background: #222; color: #ffFF99');
    console.log(dictionary);
    console.log(paramValueInit);
  }
  */
  let dict = [...dictionary];
  if (paramValueInit === KEEP_UNCHANGED) {
    dict.push(
      {
        id: KEEP_UNCHANGED,
        value: KEEP_UNCHANGED,
      }
    )
  }

  const [paramIndex, setparamIndex] = useState(index); // paramIndex_index
  const [paramValue, setparamValue] = useState(paramValueInit);
  const [paramDict, setparamDict] = useState(dict);

  const updateDict = () => {
    let newDict = paramDict.filter(item => item.id !== paramIndex);
    //    console.log('old', newDict);
    if (paramDict.find(item => item.id === paramIndex)) {
      const objectReference = paramDict.find(item => item.id === paramIndex);
      const objectToBeChanged = { ...objectReference, value: paramValue };
      /*     console.log('objectToBeChanged', objectToBeChanged);
      console.log([...newDict, objectToBeChanged]); */
      setparamDict([...newDict, objectToBeChanged]);
    }
  };

  useEffect(() => {
    setparamIndex(index);
    let paramValueInit = index === '' ? '' : index === KEEP_UNCHANGED ? KEEP_UNCHANGED : paramDict.find(item => item.id === index) ? paramDict.find(item => item.id === index).value : null;
    setparamValue(paramValueInit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    //console.log(paramIndex);
    if (paramDict.find(item => item.id === paramIndex)) {
      const newValue = paramDict.find(item => item.id === paramIndex).value;
      setparamValue(!newValue ? '' : newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramIndex]);

  useEffect(() => {
    if (paramIndex !== 'Initial') {
      updateDict();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramValue]);

  useEffect(() => {
    let changeObject = {};
    if (paramIndex !== index) {
      changeObject[`${varName}_index`] = paramIndex;
    }
    if (JSON.stringify(paramDict) !== JSON.stringify(dictionary.filter(item => item.id !== KEEP_UNCHANGED))) {
      changeObject[`${varName}_dict`] = paramDict.filter(item => item.id !== KEEP_UNCHANGED);
    }
    // console.warn('paramIndex', onUpdate);
    if (Object.keys(changeObject).length > 0) {
      //console.log('%c changeObject ready ', 'background: #222; color: #ffFF00');
      //console.log(changeObject);
      if (paramIndex === KEEP_UNCHANGED) {
        onReset(`${varName}_index`, `${varName}_dict`);
      } else {
        onUpdate(changeObject);
      }
    }

    // reset when set back to - - -


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramIndex, paramDict]);

  const validateParamIndex = () => {
    return paramDict.find(item => item.id === paramIndex) ? paramIndex : '';
  };

  return (
    <>
      {/* {paramIndex} */}
      <div className="center" key={`${varName}${index}`}>
        {paramIndex && (
          <div className={paramIndex !== 'Run' && paramIndex !== 'Group' ? 'leftSide' : 'rightSide'}>
            <FormControl className="select">
              <InputLabel shrink id="select-four">
                {label}
              </InputLabel>
              <Select
                labelId="select-one"
                id={`${varName}_index`}
                value={validateParamIndex()}
                onChange={ev => {
                  //                console.log(ev.target.value, '!==', paramIndex);
                  if (ev.target.value !== paramIndex) {
                    setparamIndex(ev.target.value);
                  }
                }}
                displayEmpty
              >
                {paramDict.map((item, index) => {
                  return (
                    <MenuItem key={`paramIndex__${item.id}`} value={item.id}>
                      {item.id}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
        <div className={paramIndex === 'Run' || paramIndex === 'Group' ? 'leftSide2' : 'rightSide'}>
          {/* <div className={paramIndex === 'Initial' || paramIndex === 'Manual' ? 'rightSide' : 'leftSide2'}> */}
          {paramIndex === 'Initial' && <TextField id={`${varName}_value`} label={` ${units}`} value={paramValue} disabled fullWidth />}
          {paramIndex === 'Manual' && (
            <TextField
              id={`${varName}_value`}
              label={` ${units}`}
              value={paramValue}
              onChange={ev => {
                if (ev.target.value !== paramValue) {
                  setparamValue(ev.target.value);
                }
              }}
              fullWidth
            />
          )}
          {paramIndex === 'Group' && <AutocompleteGroups onUpdate={setparamValue} varName={varName} value={paramValue} />}
          {paramIndex === 'Run' && <AutocompleteRuns onUpdate={setparamValue} varName={varName} value={paramValue} />}
        </div>
      </div>
    </>
  );
};
export default CalibrationVar;
