const findCommonValues = objects => {
  // console.log(objects);
  var common = JSON.parse(JSON.stringify(objects[0]));
  var unmatchedProps = {};
  for (var i = 1; i < objects.length; i++) {
    for (var prop in objects[i]) {
      //      console.log(objects[i])
      checkProps(objects[i], common, prop);
    }
    for (var commProp in common) {
      //      console.log(commProp)
      checkProps(common, objects[i], commProp);
    }
  }
  return { common: common, different: unmatchedProps };

  function checkProps(source, target, prop) {
    if (source.hasOwnProperty(prop)) {
      var val = source[prop];
      //      console.log(target.hasOwnProperty(prop)+" || "+target[prop]+" != "+val)
      if (!target.hasOwnProperty(prop) || JSON.stringify(target[prop]) !== JSON.stringify(val)) {
        unmatchedProps[prop] = true; // note: you could extend this to store values, or number of times you found this key, or whatever
        delete common[prop];
      }
    }
  }
};

export default findCommonValues;
