import React from "react";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const Nav = props => {
  let history = useHistory();
  const urls = ["/", "/groups", "/view-runs", "/view-plot"];
  const defaultValue =
    urls.indexOf(window.location.pathname) > -1
      ? urls.indexOf(window.location.pathname)
      : 0;
  //  console.log(defaultValue);
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(urls[newValue]);
    //    console.log(urls[newValue]);
  };
  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="disabled tabs example"
    >
      <Tab label="Runs" />
      <Tab label="Groups" />
      <Tab label="View Runs" />
      <Tab label="View Plot" />
    </Tabs>
  );
};
export default Nav;
