import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import MaterialTable from 'material-table';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Left } from '../icons/Left';
import { Right } from '../icons/Right';
import { Trash } from '../icons/Trash';

import axios from '../utils/axios';
import formatDate from '../utils/formatDate';
import GroupList from '../components/GroupList';
import MainContext from '../stores/mainContext';

const Groups = () => {
  const { mainContext, dispatch } = useContext(MainContext);

  const [tableData, setTableData] = React.useState([...mainContext.allRuns.filter(item => item.group.includes(mainContext.editedGroup))]);
  const [open, setOpen] = React.useState(false);
  const [runsToRemoveFromGroup, setrunsToRemoveFromGroup] = useState([]);
  const [alertPosition, setalertPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('Well done.');
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState('Unknown error occured.');

  // const [listOfRuns, setlistOfRuns] = useState(null);
  useEffect(() => {
    const newTableData = [...mainContext.allRuns.filter(item => item.group.includes(mainContext.editedGroup))].map(item => {
      return { ...item, checked: runsToRemoveFromGroup.indexOf(item.id) > -1 ? true : false };
    });
    setTableData(newTableData);
  }, [mainContext.allRuns, mainContext.editedGroup, runsToRemoveFromGroup]);

  const openDeleteConfirmation = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteConfirmed = () => {
    const oldGroups = mainContext.allGroups.filter(item => item.id !== mainContext.editedGroup);
    const go = async () => {
      try {
        setLoading(true);
        setsuccess(false);
        const response = await axios.delete(`/group/${mainContext.editedGroup}`);
        console.log(response);
        dispatch({ type: 'SET_ALL_GROUPS', payload: [...oldGroups] });
        dispatch({ type: 'SET_EDITING_GROUP', payload: null });
        giveFeedback(true, 'The group has been deleted.');
        setOpen(false);
      } catch (e) {
        setOpen(false);
        console.log(e);
        giveFeedback(false, e.toString());
        throw e;
      }
    };
    go();
  };

  /*   const emptyRef = useRef(null);
  const emptyRefSecond = useRef(null);

  useEffect(() => {
    //console.log(emptyRef.current.parentNode);

    if (emptyRef.current !== null) {
      const tableBorder = emptyRef.current.parentNode;
      tableBorder.style.borderBottom = 'none';
    }
    if (emptyRefSecond.current !== null) {
      const tableBorder = emptyRefSecond.current.parentNode;
      tableBorder.style.borderBottom = 'none';
    }
  }, [emptyRef]); */

  const [readyToAdd, setreadyToAdd] = useState(false);
  useEffect(() => {
    let editedRunsDifferFromSelectedGroup = mainContext.editedGroup ? true : false;
    if (editedRunsDifferFromSelectedGroup) {
      const newItems = mainContext.editedRuns.map(item => !item.group.includes(mainContext.editedGroup));
      setreadyToAdd(newItems.includes(true));
    }
  }, [mainContext.allRuns, mainContext.editedRuns, mainContext.editedGroup]);

  const addSelectionToGroup = () => {
    setalertPosition(1);

    // create new array for HTTP request
    let newEditedRuns = [...mainContext.editedRuns];
    newEditedRuns = newEditedRuns.map(item => {
      let newItem = { ...item };
      if (!item.group.includes(mainContext.editedGroup)) {
        // if editedRun doesnt belong to curently selected group
        newItem.group = [...item.group, mainContext.editedGroup];
      }
      return newItem;
    });

    let allRuns = [...newEditedRuns, ...tableData]

    console.log('mainContext.editedRuns', mainContext.editedRuns);
    console.log('newEditedRuns', allRuns);
    const go = async () => {
      try {
        setLoading(true);
        setsuccess(false);
        const response = await axios.put(`/group/${mainContext.editedGroup}`, {
          run_ids: [...new Set(allRuns.map(item => item.run_id))]
        });
        console.log(response);
        dispatch({ type: 'SET_EDITING_RUNS', payload: [...mainContext.editedRuns, ...newEditedRuns] });
        let newAllRuns = [...mainContext.allRuns];
        // mutate array directly so you don't need to refetch all the runs
        mainContext.editedRuns.map(item => {
          if (!item.group.includes(mainContext.editedGroup)) {
            // if editedRun doesnt belong to curently selected group
            item.group.push(mainContext.editedGroup);
            // mutating object directly!!!!
          }
          return item;
        });

        dispatch({ type: 'SET_ALL_RUNS', payload: newAllRuns });
        giveFeedback(true, 'Runs have been added to the group. ');
      } catch (e) {
        console.log(e);
        giveFeedback(false, e.toString());
        throw e;
      }
    };
    go();
  };
  const removeSelectionFromGroup = () => {
    setalertPosition(1);

    const allRunsInThisGroup = [...mainContext.allRuns.filter(item => item.group.includes(mainContext.editedGroup))];
    console.log('allRunsInThisGroup', allRunsInThisGroup);
    console.log('runsToRemoveFromGroup', runsToRemoveFromGroup);
    const runsInThisGroupAfterRemoval = allRunsInThisGroup.filter(item => {
      //console.log(item.id, runsToRemoveFromGroup);
      return runsToRemoveFromGroup.indexOf(item.id) > -1 ? false : true;
    });
    const runsIDsInThisGroupAfterRemoval = runsInThisGroupAfterRemoval.map(item => item.id);
    console.log('runsIDsInThisGroupAfterRemoval', runsIDsInThisGroupAfterRemoval);

    // console.log(mainContext.editedRuns);
    // console.log('newAllRuns', newAllRuns);
    const go = async () => {
      try {
        setLoading(true);
        setsuccess(false);
        const response = await axios.put(`/group/${mainContext.editedGroup}`, {
          run_ids: runsIDsInThisGroupAfterRemoval
        });
        console.log(response);
        // mutate array directly so you don't need to refetch all the runs
        const newAllRuns = mainContext.allRuns.map(item => {
          if (runsToRemoveFromGroup.includes(item.run_id)) {
            const index = item.group.indexOf(mainContext.editedGroup);
            if (index > -1) {
              item.group.splice(index, 1);
            }
            // mutating object directly!!!!
          }
          return item;
        });
        console.log('mainContext.editedRuns', mainContext.editedRuns);
        console.log('newAllRuns', newAllRuns);
        dispatch({ type: 'SET_ALL_RUNS', payload: newAllRuns });
        dispatch({
          type: 'SET_EDITING_RUNS',
          payload: mainContext.editedRuns.filter(item => {
            //console.log(item.id, runsToRemoveFromGroup);
            return runsToRemoveFromGroup.indexOf(item.id) > -1 ? false : true;
          })
        });
        giveFeedback(true, 'Runs have been removed from the group. ');
      } catch (e) {
        console.log(e);
        giveFeedback(false, e.toString());
        throw e;
      }
    };
    go();
  };

  const giveFeedback = (isSuccessfull, message) => {
    setLoading(false);
    if (isSuccessfull) {
      setsuccess(true);
      seterror(false);
      setsuccessMessage(message);
    } else {
      seterror(true);
      setsuccess(false);
      seterrorMessage(message);
    }
  };

  return (
    <div className="groups">
      {/* Group management */}
      <GroupList />

      {/* Selection & Group management buttons */}
      <div className="center">
        <div className="line">
          <Button
            variant="outlined"
            startIcon={<Left />}
            color={`${mainContext.editedGroup !== null && mainContext.editedRunsDifferFromSelectedGroup ? 'primary' : 'default'}`}
            className={`classicButton ${mainContext.editedGroup !== null && mainContext.editedRunsDifferFromSelectedGroup ? '' : 'default'}`}
            onClick={ev => {
              if (mainContext.editedGroup !== null && mainContext.editedRunsDifferFromSelectedGroup) {
                dispatch({ type: 'SET_EDITING_RUNS', payload: [...mainContext.allRuns.filter(item => item.group.includes(mainContext.editedGroup))] });
              }
            }}
          >
            Apply Group as Selection
          </Button>
        </div>
        <div className="line">
          <Button
            variant="outlined"
            startIcon={<Right />}
            color={`${readyToAdd ? 'primary' : 'default'}`}
            className={`classicButton ${readyToAdd ? '' : 'default'}`}
            onClick={ev => {
              if (readyToAdd) {
                addSelectionToGroup();
              }
            }}
          >
            Add Selection to Group
          </Button>
          <Button
            variant="outlined"
            startIcon={<Trash />}
            onClick={openDeleteConfirmation}
            color={`${mainContext.editedGroup ? 'secondary' : 'default'}`}
            className={`classicButton ${mainContext.editedGroup ? '' : 'default'}`}
          >
            Delete Group
          </Button>

          <Dialog className="modal" open={open} onClose={handleClose} aria-labelledby="Group deletion" aria-describedby="Do you want to delete the group?">
            <p>
              Do you want to
              <br />
              delete the group?
            </p>
            <div className="line">
              <Button variant="contained" onClick={deleteConfirmed} className="classicButton" color="secondary">
                Delete
              </Button>
              <Button variant="contained" onClick={handleClose} className="classicButton ">
                Cancel
              </Button>
            </div>
          </Dialog>
        </div>
      </div>

      {/* Changes in contined runs */}
      {loading && alertPosition === 1 && <LinearProgress style={{ marginTop: '-20px', marginBottom: '20px' }} />}
      {success && alertPosition === 1 && (
        <Alert
          severity="success"
          style={{ marginTop: '-20px' }}
          onClose={() => {
            setsuccess(false);
          }}
        >
          <AlertTitle>Success saving changes!</AlertTitle>
          <p>{successMessage}</p>
        </Alert>
      )}
      {error && alertPosition === 1 && (
        <Alert
          severity="error"
          style={{ marginTop: '-20px' }}
          onClose={() => {
            seterror(false);
          }}
        >
          <AlertTitle>Errors while saving changes.</AlertTitle>
          <p>{errorMessage}</p>
        </Alert>
      )}

      <div className="bottom">
        <h2>Contains run(s)</h2>
        <MaterialTable
          title=""
          columns={[
            {
              title: '',
              field: 'select',
              cellStyle: { padding: 0, width: 20 },
              headerStyle: { padding: 0, width: 20 },
              render: rowData => {
                //console.log(rowData.checked);
                return (
                  <input
                    readOnly
                    type="checkbox"
                    checked={rowData.checked}
                    style={{ zoom: 1.5 }}
                    onClick={ev => {
                      // console.log(ev.target.checked)
                      if (ev.target.checked) {
                        setrunsToRemoveFromGroup(oldValue => {
                          if (!oldValue.includes(rowData.run_id)) {
                            return [...oldValue, rowData.run_id];
                          }
                          return [...oldValue];
                        });
                      } else {
                        setrunsToRemoveFromGroup(oldValue => {
                          if (oldValue.includes(rowData.run_id)) {
                            return oldValue.filter(item => item !== rowData.run_id);
                          }
                          return [...oldValue];
                        });
                      }
                    }}
                  />
                );
              }
            },
            {
              title: 'Date',
              field: 'run_ts',
              render: rowData => formatDate(rowData.run_ts)
            },
            { title: 'Description', field: 'description' },
            { title: 'Rider', field: 'rider_name_run' }
          ]}
          data={tableData}
          options={{
            title: false,
            paging: false,
            search: false,
            selection: false,
            style: { border: 0, margin: '1rem' }
            /* selectionProps: (rowData) => ({
              disabled: rowData.name === "Mehmet",
              color: "primary",
            }), */
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <p
                  className="empty"
                  //                  ref={emptyRefSecond}
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    marginTop: '1.5rem'
                  }}
                ></p>
              )
            }
          }}
        />
        <Button
          variant="outlined"
          startIcon={<Trash />}
          color={`${runsToRemoveFromGroup.length > 0 ? 'secondary' : 'default'}`}
          className={`classicButton ${runsToRemoveFromGroup.length > 0 ? '' : 'default'}`}
          onClick={ev => {
            console.log(runsToRemoveFromGroup);
            removeSelectionFromGroup();
          }}
        >
          Remove Runs from Group
        </Button>
      </div>
    </div>
  );
};
export default Groups;
