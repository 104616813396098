import React, { useState, useContext } from 'react';
import { Button, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import axios from '../utils/axios';
import MainContext from '../stores/mainContext';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const RunFilter = ({ onClose }) => {
  const { mainContext, dispatch } = useContext(MainContext);
  const [startDate, setstartDate] = useState(mainContext?.filterVars?.startDate);
  const [endDate, setendDate] = useState(mainContext?.filterVars?.endDate);
  const [focusedInput, setfocusedInput] = useState(null);
  const [rider, setrider] = useState(mainContext?.filterVars?.rider);
  const allRiders = [...mainContext?.allRiders];

  const setFilter = () => {
    console.log('filtering');
    dispatch({ type: 'SET_FILTER_VARS', payload: { startDate: startDate, endDate: endDate, rider: rider } });
    localStorage.setItem('changesInProgress', true);
    const getData = async () => {
      try {
        const response = await axios.get(`/run/?riders=[${rider}]&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
        dispatch({ type: 'SET_ALL_RUNS', payload: response.data.runs });
        //dispatch({ type: 'SET_ALL_RIDERS', payload: response.data.riders });
        dispatch({ type: 'SET_CALCS', payload: response.data.cal_calc });
        //console.log('novy', response);
        /*         const response2 = await axios.get('/group/');
        dispatch({ type: 'SET_ALL_GROUPS', payload: response2.data }); */
        const editedRunIdsFromLS = localStorage.getItem('editedRuns');
        const editedRunIds = editedRunIdsFromLS ? (editedRunIdsFromLS.indexOf(',') > -1 ? JSON.parse(editedRunIdsFromLS) : editedRunIdsFromLS) : [];
        const editedRunsFromLS = response.data.runs.filter(item => editedRunIds.includes(item.id));
        dispatch({
          type: 'SET_EDITING_RUNS',
          payload: editedRunsFromLS
        });
        dispatch({ type: 'SET_EDITING_GROUP', payload: JSON.parse(localStorage.getItem('editedGroup')) });
      } catch (e) {
        throw e;
      }
    };
    getData();
    localStorage.setItem('changesInProgress', false);
    onClose();
  };

  return (
    <div style={{ padding: '0.5rem 1rem 1rem' }}>
      <p>Filter runs</p>
      <br />
      <div>
        {' '}
        <DateRangePicker
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            setstartDate(startDate);
            setendDate(endDate);
            //this.setState({ startDate, endDate })
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => setfocusedInput(focusedInput)} // PropTypes.func.isRequired,
          displayFormat={'DD. MM. YYYY'}
          keepOpenOnDateSelect={false}
          isOutsideRange={() => {
            return false;
          }}
        //enableOutsideDays=
        />
        <br />
        <br />
        <div className="quarter" style={{ width: '100%' }}>
          <FormControl className="select" style={{ width: '100%' }}>
            <InputLabel shrink id="rider">
              Rider {/* {rider.length === 0 ? ['all'] : rider} */}
            </InputLabel>
            <Select
              labelId="select-one"
              id={`riders`}
              value={rider.length === 0 ? ['all'] : rider.length === allRiders.length ? ['all'] : rider}
              fullWidth
              multiple
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              onChange={ev => {
                if (ev.target.value !== rider) {
                  console.log('ev.target.value', ev.target.value, allRiders);
                  console.log(ev.target.value.length, '===', allRiders.length + 1);

                  if (ev.target.value.indexOf('all') > -1 && ev.target.value.length === allRiders.length + 1) {
                    console.log('setting', ['all', ...allRiders]);
                    setrider(['all', ...allRiders]);
                  } else {
                    let newArray = [...ev.target.value];
                    if (newArray.indexOf('all') > -1) {
                      const allIndex = newArray.indexOf('all');
                      newArray.splice(allIndex, 1);
                    }
                    console.log('setting', [...newArray]);
                    setrider([...newArray]);
                  }
                }
              }}
            >
              <MenuItem key={`riders_0`} value="all">
                all
              </MenuItem>
              {allRiders.map((item, index) => {
                return (
                  <MenuItem key={`riders_${index}`} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <br />
      <div className="line">
        <Button variant="contained" onClick={setFilter} className="classicButton" color="secondary">
          Set
        </Button>
        <Button variant="contained" onClick={onClose} className="classicButton ">
          Cancel
        </Button>
      </div>
    </div>
  );
};
export default RunFilter;
