import React, { useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import MaterialTable from 'material-table';
import MainContext from '../stores/mainContext';
import { Trash } from '../icons/Trash';
import axios from '../utils/axios';

const GroupTable = props => {
  const { mainContext, dispatch } = useContext(MainContext);
  const { groupsIDs } = props;
  const removeSelectionFromGroup = () => {
    setsuccess(true);
    let newEditedRuns = [...mainContext.editedRuns];

    const go = async () => {
      try {
        setLoading(true);
        setsuccess(false);
        let responses = [];
        /*
        for each group
         - get all runids
         - get runids which are in editedRuns and this group // => remove them
         - make a request to server
        */
        for (let i = 0; i < groupsToRemove.length; i++) {
          const allRunIdsInThisGroup = mainContext.allRuns.filter(item => item.group.includes(groupsToRemove[i])).map(item => item.run_id);
          console.log('allRunIdsInThisGroup', allRunIdsInThisGroup);
          let runIdsToBeRemoved = newEditedRuns.filter(item => item.group.includes(groupsToRemove[i])).map(item => item.run_id);
          console.log('runIdsToBeRemoved', runIdsToBeRemoved);
          let reducedRunIdsInThisGroup = [...allRunIdsInThisGroup];
          runIdsToBeRemoved.forEach(runId => {
            const index = reducedRunIdsInThisGroup.indexOf(runId);
            if (index > -1) {
              reducedRunIdsInThisGroup.splice(index, 1);
            }
          });
          console.log('reducedRunIdsInThisGroup', reducedRunIdsInThisGroup);
          const response = await axios.put(`/group/${groupsToRemove[i]}`, {
            run_ids: reducedRunIdsInThisGroup
          });
          responses.push(response);
        }
        console.log('No.of requests', responses.length);
        const responseOfNewRuns = await axios.get('/run/');
        dispatch({ type: 'SET_ALL_RUNS', payload: responseOfNewRuns.data.runs });
        /*
        get allRuns
         - get allRuns which .group contains group which was removed // => remove them
         - dispatch
        */
        giveFeedback(true, 'Runs have been removed from the group. ');
      } catch (e) {
        console.log(e);
        giveFeedback(false, e.toString());
        throw e;
      }
    };
    go();
  };

  const giveFeedback = (isSuccessfull, message) => {
    setLoading(false);
    if (isSuccessfull) {
      setsuccess(true);
      seterror(false);
      setsuccessMessage(message);
    } else {
      seterror(true);
      setsuccess(false);
      seterrorMessage(message);
    }
  };

  const [groupsToRemove, setgroupsToRemove] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('Well done.');
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState('Unknown error occured.');

  return (
    <>
      {loading && <LinearProgress style={{ marginTop: '20px' }} />}
      {success && (
        <Alert
          severity="success"
          style={{ marginTop: '20px' }}
          onClose={() => {
            setsuccess(false);
          }}
        >
          <AlertTitle>Success saving changes!</AlertTitle>
          <p>{successMessage}</p>
        </Alert>
      )}
      {error && (
        <Alert
          severity="error"
          style={{ marginTop: '20px' }}
          onClose={() => {
            seterror(false);
          }}
        >
          <AlertTitle>Errors while saving changes.</AlertTitle>
          <p>{errorMessage}</p>
        </Alert>
      )}
      <MaterialTable
        title=""
        columns={[
          {
            title: '',
            field: 'select',
            cellStyle: { padding: 0, width: 20 },
            headerStyle: { padding: 0, width: 20 },
            render: rowData => {
              //              console.log(rowData.checked);
              return (
                <input
                  readOnly
                  type="checkbox"
                  checked={rowData.checked}
                  style={{ zoom: 1.5 }}
                  onClick={ev => {
                    // console.log(ev.target.checked)
                    if (ev.target.checked) {
                      setgroupsToRemove(oldValue => {
                        if (!oldValue.includes(rowData.id)) {
                          return [...oldValue, rowData.id];
                        }
                        return [...oldValue];
                      });
                    } else {
                      setgroupsToRemove(oldValue => {
                        if (oldValue.includes(rowData.id)) {
                          return oldValue.filter(item => item !== rowData.id);
                        }
                        return [...oldValue];
                      });
                    }
                  }}
                />
              );
            }
          },
          //          { title: 'ID', field: 'id' },
          { title: 'Tag', field: 'group_tag' },
          { title: 'Description', field: 'description' },
          {
            title: 'Count',
            field: 'count',
            render: rowData => {
              let memberCount = mainContext.allRuns.filter(item => item.group.includes(rowData.id));
              return memberCount.length;
            }
          }
        ]}
        data={[...mainContext.allGroups.filter(item => groupsIDs.includes(item.id))]}
        options={{
          title: false,
          paging: false,
          search: false,
          selection: false,
          style: { border: 0, margin: '1rem' },
          selectionProps: rowData => ({
            disabled: rowData.name === 'Mehmet',
            color: 'primary'
          })
        }}
      />
      <Button
        variant="outlined"
        startIcon={<Trash />}
        color={`${groupsToRemove.length > 0 ? 'secondary' : 'default'}`}
        className={`classicButton ${groupsToRemove.length > 0 ? '' : 'default'}`}
        onClick={ev => {
          //console.log(groupsToRemove);
          removeSelectionFromGroup();
        }}
      >
        {/* <Button variant="outlined" startIcon={<Trash />} className="classicButton"  onClick={handleClickOpen}> */}
        Remove from Selected Groups
      </Button>

      {/*             <Dialog className="modal" open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <p>
                Do you want to
                <br />
                delete the group?
              </p>
              <div className="line">
                <Button variant="contained" className="classicButton red">
                  Delete
                </Button>
                <Button variant="contained" onClick={handleClose} className="classicButton ">
                  Cancel
                </Button>
              </div>
            </Dialog> */}
    </>
  );
};
export default GroupTable;
