export const findOptionByValue = (options, value) => {
  const result = options.filter(obj => {
    return obj.value === value
  })
  return result.length > 0 ? result[0] : false
};

export const getOptionNameByValue = (options, value) => {
  const result = findOptionByValue(options, value)
  if (result && result.name) {
    return result.name
  } else {
    return ''
  }
};
