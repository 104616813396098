import React, { useEffect, useState, useContext } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FilterList from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainContext from '../stores/mainContext';
import RunFilter from './RunFilter';
import ReactTooltip from 'react-tooltip';
import axios from '../utils/axios';
import formatDate from '../utils/formatDate'


const RunList = () => {
  const { mainContext, dispatch } = useContext(MainContext);
  const [data, setdata] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const syncRunListFromStore = myArray => {
    /*     const editedRunsFromLS = localStorage.getItem('editedRuns');
    console.log('editedRunsFromLS', editedRunsFromLS); */

    const arrayWithCheckvalue = myArray.map(item => {
      const isRunSelected = mainContext.editedRuns.find(item2 => {
        return item.id === item2.id;
      });
      /* const isRunSelected = true;*/
      //const isRunSelected2 = editedRunsFromLS.includes(item.id);
      //console.log('isRunSelected2', isRunSelected2);
      return { ...item, checked: isRunSelected ? true : false };
    });
    if (arrayWithCheckvalue.length > 0) setdata([...arrayWithCheckvalue]);
  };

  const checkIfEditedRunsDifferFromSelectedGroup = () => {
    let editedRunsDifferFromSelectedGroup = mainContext.editedGroup ? true : false;
    if (editedRunsDifferFromSelectedGroup) {
      const allRunsInGroup = mainContext.allRuns.filter(item => item.group.includes(mainContext.editedGroup));
      const allRunIds = JSON.stringify(allRunsInGroup.map(item => item.id));
      //const editedRunsInGroup = mainContext.editedRuns.filter((item) => item.group.includes(mainContext.editedGroup));
      const editedRunsIds = JSON.stringify(mainContext.editedRuns.map(item => item.id));
      //      console.log(allRunIds, ' !== ', editedRunsIds);
      editedRunsDifferFromSelectedGroup = allRunIds !== editedRunsIds; //runsInGroup.length < mainContext.editedRuns.length;
      //      console.log(editedRunsDifferFromSelectedGroup);
    }
    if (editedRunsDifferFromSelectedGroup) {
      dispatch({ type: 'SET_DIF_GROUP', payload: true });
    } else {
      dispatch({ type: 'SET_DIF_GROUP', payload: false });
    }
  };


  const reprocess = async () => {
    try {
      if (mainContext?.editedRuns) {
        const editedRunIds = mainContext.editedRuns.map(item => item.id);
        const reprocesStatus = await axios.put('/run-reprocess/', editedRunIds);
        setSuccess(true)
      }

      //giveFeedback(true, 'Run(s) saved.');
    } catch (e) {
      setSuccess(false)
      setError(true)
      throw e;
    }
  };

  useEffect(() => {
    syncRunListFromStore(mainContext.allRuns);
    checkIfEditedRunsDifferFromSelectedGroup();
    ReactTooltip.rebuild();
    setError(false)
    setSuccess(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.allRuns, mainContext.editedRuns]);

  useEffect(() => {
    checkIfEditedRunsDifferFromSelectedGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.editedGroup]);

  const [selectAll, setselectAll] = React.useState(false);
  // Dialog
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClose = () => {
    setDialogOpen(false);
  };
  const [filterOpen, setfilterOpen] = React.useState(false);

  return (
    <div>
      {!filterOpen && (
        <p onClick={() => setfilterOpen(true)} className="classicButton big filterButton">
          <span>
            <FilterList /> &nbsp; {/* 27. 12. 2020 - 21. 12. 2021 */}
            {mainContext?.filterVars?.startDate.format('DD. MM. YYYY')} - {mainContext?.filterVars?.endDate.format('DD. MM. YYYY')}
          </span>
          <span>{mainContext?.filterVars?.rider.join(', ')}</span>
        </p>
      )}

      {/*       <Dialog
        className="modal"
        fullScreen
        //maxWidth="lg"
        //fullWidth
        open={dialogOpen2}
        onClose={() => setDialogOpen2(false)}
        aria-labelledby="Filter runs"
        aria-describedby="Filter by date range and rider name"
        >
        <div>
        <RunFilter onClose={() => setDialogOpen2(false)} />
        </div>
      </Dialog> */}
      {filterOpen && <RunFilter onClose={() => setfilterOpen(false)} />}
      <div className={`list-scroll ${filterOpen ? 'filter-open' : ''}`}>

        <table className="table">
          <tbody>
            <tr>
              <th>
                <input
                  readOnly
                  type="checkbox"
                  checked={selectAll}
                  style={{ zoom: 1.5 }}
                  onClick={ev => {
                    if (localStorage.getItem('changesInProgress') !== 'true') {
                      if (!selectAll) {
                        setselectAll(true);
                        dispatch({ type: 'SET_EDITING_RUNS', payload: mainContext.allRuns });
                      } else {
                        setselectAll(false);
                        dispatch({ type: 'SET_EDITING_RUNS', payload: [] });
                      }
                    } else {
                      setDialogOpen(true);
                      ev.preventDefault();
                    }
                  }}
                />
              </th>
              <th>Date / Name </th>
              <th>Rider </th>
            </tr>
            {data.map((rowItem, index) => {
              return (
                <tr key={`runId${index}`} className={rowItem.needs_reprocess === true ? 'outdated' : ''} data-tip={rowItem.needs_reprocess === true ? 'Needs reprocessing' : ''}>
                  <td>
                    <input
                      readOnly
                      type="checkbox"
                      checked={rowItem.checked}
                      style={{ zoom: 1.5 }}
                      onClick={ev => {
                        if (localStorage.getItem('changesInProgress') !== 'true') {
                          if (rowItem.checked) {
                            dispatch({ type: 'SET_EDITING_RUNS', payload: mainContext.editedRuns.filter(item => item.id !== rowItem.id) });
                          } else {
                            dispatch({ type: 'SET_EDITING_RUNS', payload: [...mainContext.editedRuns, { ...rowItem }] });
                          }
                        } else {
                          setDialogOpen(true);
                          ev.preventDefault();
                        }
                      }}
                    />
                  </td>
                  <td>
                    <>
                      {formatDate(rowItem.run_ts)} <br />
                      {rowItem.description}
                      {/* <br />
                    <span style={{ fontSize: '11px' }}>{rowItem.id}</span> */}
                    </>
                  </td>
                  <td>{rowItem.rider_name_run}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {mainContext.isLoading === true && (
        <div style={{ width: '100%', textAlign: 'center', margin: '2rem auto' }}>
          <CircularProgress />
        </div>
      )}
      {mainContext.allRuns.length === 0 && <div style={{ width: '100%', textAlign: 'center', margin: '2rem auto' }}>No runs.</div>}


      <div className="runs-actions">
        {mainContext?.editedRuns?.length >= 1 &&
          <Button variant="outlined" className="classicButton reprocess" color="primary" type="button" onClick={reprocess}>
            Reprocess Runs
          </Button>
        }

        {success && (
          <Alert
            severity="success"
            style={{}}
            onClose={() => {
              setSuccess(false);
            }}
          >
            <AlertTitle>Reprocessing successful.</AlertTitle>
          </Alert>
        )}

        {error && (
          <Alert
            severity="error"
            style={{ marginBottom: '20px' }}
            onClose={() => {
              setError(false);
            }}
          >
            <AlertTitle>Reprocessing error.</AlertTitle>
          </Alert>
        )}
      </div>

      <Dialog className="modal" open={dialogOpen} onClose={handleClose} aria-labelledby="Changes detected" aria-describedby="Please save or discard your edits first.">
        <h2 style={{ margin: ' 0 auto 1.5rem', fontSize: '1.5rem' }}>Warning</h2>
        <p style={{ margin: '0rem 2rem 1rem', textTransform: 'none', lineHeight: 1.5 }}>
          Changes detected. <br /> Please save or discard your edits first.
        </p>
        <br />
        <div className="line">
          <Button variant="contained" color="primary" className="classicButton" onClick={handleClose} style={{ margin: 0, padding: '0.4rem 3rem' }}>
            OK
          </Button>
          {/* <Button variant="contained" onClick={handleClose} className="classicButton ">
                  Cancel
                </Button> */}
        </div>
      </Dialog>
      <ReactTooltip />
    </div>
  );
};
export default RunList;
