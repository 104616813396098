import React, { useState, useEffect, useContext } from 'react';
import { Button, TextField } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import GroupTable from '../components/GroupTable';
import CalibrationVar from '../components/CalibrationVar';
import { Cloud } from '../icons/Cloud';
import { Close } from '../icons/Close';
import { Check } from '../icons/Check';
import MainContext from '../stores/mainContext';
import axios from '../utils/axios';
import findCommonValues from '../utils/findCommonValues';
import { KEEP_UNCHANGED } from '../const'

const Runs = props => {
  const { mainContext, dispatch } = useContext(MainContext);
  let { different, common } = findCommonValues(mainContext.editedRuns);
  const getGroupIds = objects => {
    //  - get all groups associated to edite runs
    //  - flat them and remove dupes
    let allGroups = objects.map(item => item.group);
    allGroups = allGroups.flat();
    return allGroups.filter(function (item, pos) {
      return allGroups.indexOf(item) === pos;
    });
  };

  const [groupsIDs, setgroupsIDs] = useState(getGroupIds(mainContext.editedRuns));

  const getDictionary = (key) => {
    var dict = editedRun[key + '_dict']
    /*
    let index = editedRun[key + '_index'];
    if (index && (index === 'Group' || index === 'Run')) {
      let options = mainContext.calCalc[key]
      dict = options[index]
    }

    console.log(dict || [])
    */
    return dict || []
  }

  const populateEditedRun = () => {
    // different fields to become "" 
    const defaultValues = {};
    for (const key of Object.keys(different)) {
      defaultValues[key] = KEEP_UNCHANGED;
    }

    return {
      ...defaultValues,
      // common values to be copied over
      ...common,
      // and dictionaries to be taken from first run
      combined_mass_dict: mainContext.editedRuns[0].combined_mass_dict,
      wheel_circumference_dict: mainContext.editedRuns[0].wheel_circumference_dict,
      power_meter_slope_dict: mainContext.editedRuns[0].power_meter_slope_dict,
      cp_corr_dict: mainContext.editedRuns[0].cp_corr_dict,
      crr_dict: mainContext.editedRuns[0].crr_dict,
      yaw_offset_dict: mainContext.editedRuns[0].yaw_offset_dict,
      route_dict: mainContext.editedRuns[0].route_dict,
      gates_dict: mainContext.editedRuns[0].gates_dict
    };

  };
  const [editedRun, setEditedRun] = useState(populateEditedRun());


  useEffect(() => {
    const reload = async () => {
      try {
        setSuccessHeadline('Run(s) reloaded')
        setLoading(true);
        setsuccess(false);
        const responseOfNewRuns = await axios.get('/run/');
        dispatch({ type: 'SET_ALL_RUNS', payload: responseOfNewRuns.data.runs });
        dispatch({ type: 'SET_ALL_RIDERS', payload: responseOfNewRuns.data.riders });
        dispatch({ type: 'SET_CALCS', payload: responseOfNewRuns.data.cal_calc });
        giveFeedback(true, '');
      } catch (e) {
        console.log(e);
        giveFeedback(false, e.toString());
        throw e;
      }
    };
    if (mainContext.needsRerun === true) {
      reload();
    }

  }, [mainContext.needsRerun]);

  useEffect(() => {
    const newGroupIds = getGroupIds(mainContext.editedRuns);
    setgroupsIDs(newGroupIds);
    setEditedRun(populateEditedRun());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.editedRuns]);

  useEffect(() => {
    //console.log('editedRuns', mainContext.editedRuns);
    //console.log('editedRun', editedRun);
    //console.log('common', common);
    //console.log('different', different);
    /* console.log(calculateValues(getValues()));
    console.log(originalValues); */
    setoriginalValues(
      calculateValues(
        pruffixEditing
          ? {
            prefix: '',
            suffix: '',
            description: editedRun.description,
            rider_name_run: editedRun.rider_name_run
          }
          : {
            description: editedRun.description,
            rider_name_run: editedRun.rider_name_run
          }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedRun]);


  useEffect(() => {
    setValue('description', different.description === true ? KEEP_UNCHANGED : common.description);
    setValue('rider_name_run', different.rider_name_run === true ? KEEP_UNCHANGED : common.rider_name_run);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [common, different]);

  useEffect(() => {
    // console.log('allRuns changed!');
    const editedRunIds = mainContext.editedRuns.map(item => item.id);
    const editedRunsFromLS = mainContext.allRuns.filter(item => editedRunIds.includes(item.id));
    dispatch({
      type: 'SET_EDITING_RUNS',
      payload: editedRunsFromLS
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.allRuns]);

  const pruffixEditing = mainContext.editedRuns.length > 1;

  const methods = useForm({
    defaultValues: { ...editedRun },
    reValidateMode: 'onBlur'
  });

  const { handleSubmit, register, control, getValues, setValue } = methods;
  const onSubmit = data => {
    console.log(data);
    const newValues = calculateValues({ ...data, ...calParamsToUpdate });
    //console.warn(JSON.stringify(newValues.combined_mass_dict) === JSON.stringify(originalValues.combined_mass_dict));

    /*
    console.warn(' -------------- comparison ');
    console.warn(findCommonValues([originalValues, newValues]));
    console.warn(' -------------- original ');
    console.warn(originalValues);
    console.warn(' -------------- new ');
    console.warn(newValues);
    */
    const objectComparison = findCommonValues([originalValues, newValues]);

    const changeDetected = Object.keys(objectComparison.different).length > 0;
    //console.log(changeDetected);
    // console.log(objectComparison.different);
    if (changeDetected) {
      const changedProps = [...Object.keys(objectComparison.different)];
      // console.log('changedProps', changedProps);

      let changesAsObject = {};
      changedProps.forEach(item => {
        //if (newValues[item] !== KEEP_UNCHANGED) {
        changesAsObject[item] = newValues[item];
        //}
      });
      //console.log('newValues', newValues);

      const go = async () => {
        try {
          setLoading(true);
          setsuccess(false);
          console.warn(changesAsObject);

          let responses = [];
          for (let i = 0; i < mainContext.editedRuns.length; i++) {
            // apply prefix & suffix to description name
            /* if (changesAsObject.prefix || changesAsObject.suffix) {
              const prefix = changesAsObject.prefix ? changesAsObject.prefix : '';
              const suffix = changesAsObject.suffix ? changesAsObject.suffix : '';
               let new_description = prefix + mainContext.editedRuns[i].description + suffix;
              changesAsObject.description = new_description;
              delete changesAsObject.prefix;
              delete changesAsObject.suffix;
            } */
            if (changesAsObject.prefix) {
              changesAsObject.description_prefix = changesAsObject.prefix + ' ';
              delete changesAsObject.prefix;
            }
            if (changesAsObject.suffix) {
              changesAsObject.description_suffix = ' ' + changesAsObject.suffix;
              delete changesAsObject.suffix;
            }
            console.log('changesAsObject', changesAsObject);
            const response = await axios.put(`/run/${mainContext.editedRuns[i].id}`, changesAsObject);
            responses.push(response);
          }
          console.log('No. of requests', responses.length);
          const responseOfNewRuns = await axios.get('/run/');
          dispatch({ type: 'SET_ALL_RUNS', payload: responseOfNewRuns.data.runs });
          setSuccessHeadline(false)
          giveFeedback(true, 'Run(s) saved.');
        } catch (e) {
          console.log(e);
          setSuccessHeadline(false)
          giveFeedback(false, e.toString());
          throw e;
        }
      };
      go();
      /* Object.entries(changesAsObject).map((item) => {
      console.log(item); 
    }); */
    } else {
      setsuccess(false);
    }
  };
  const giveFeedback = (isSuccessfull, message) => {
    setLoading(false);
    if (isSuccessfull) {
      setsuccess(true);
      seterror(false);
      setsuccessMessage(message);
    } else {
      seterror(true);
      setsuccess(false);
      seterrorMessage(message);
    }
  };
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('Well done.');
  const [successHeadline, setSuccessHeadline] = useState(false);

  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState('Unknown error occured.');

  const resetFormToOriginals = data => {
    setValue('description', originalValues.description);
    setValue('rider_name_run', originalValues.rider_name_run);
    if (pruffixEditing) {
      setValue('prefix', originalValues.prefix);
      setValue('suffix', originalValues.suffix);
    }
    localStorage.setItem('changesInProgress', false);
  };
  const calculateValues = data => {
    const newValues = {
      ...editedRun,
      ...data
    };
    //console.log('calculatedValues', newValues);
    return newValues;
  };

  const [originalValues, setoriginalValues] = useState(
    calculateValues(
      pruffixEditing
        ? {
          ...editedRun,
          description: editedRun.description,
          rider_name_run: editedRun.rider_name_run,
          prefix: '',
          suffix: ''
        }
        : {
          ...editedRun,
          description: editedRun.description,
          rider_name_run: editedRun.rider_name_run
        }
    )
  );
  const [calParamsToUpdate, setncalParamsToUpdate] = useState({});

  const updateCalibrationParams = updateObject => {
    setncalParamsToUpdate({ ...calParamsToUpdate, ...updateObject });
    const newValuesObject = { ...getValues(), ...updateObject };
    //console.log('newValuesObject', newValuesObject);
    const newValues = calculateValues(newValuesObject);
    //console.log('originalValues', originalValues);
    //console.log('updateObject', updateObject);
    //console.log('newValues', newValues);
    const objectComparison = findCommonValues([originalValues, newValues]);
    // console.log('originalValues', originalValues);
    // console.log('newValues', newValues);
    //console.log(objectComparison);
    const changeDetected = Object.keys(objectComparison.different).length > 0;
    if (changeDetected !== (localStorage.getItem('changesInProgress') === 'true')) {
      // localStorage.setItem('changesInProgress', changeDetected);
    } else {
      localStorage.setItem('changesInProgress', false);
    }
  };


  const removeCalibrationParams = (index, dic) => {
    let current = { ...calParamsToUpdate }
    delete (current[index])
    delete (current[dic])
    setncalParamsToUpdate(current);
  }

  //console.log(editedRun.combined_mass_index);
  //console.log(editedRun.combined_mass_dict);

  return (
    <div className="runs">
      {loading && <LinearProgress style={{ marginBottom: '30px' }} />}
      {success && (
        <Alert
          severity="success"
          style={{ marginBottom: '20px' }}
          onClose={() => {
            setsuccess(false);
          }}
        >
          <AlertTitle>{successHeadline || 'Success saving changes'}.</AlertTitle>
          {successMessage && <p>{successMessage}</p>}
        </Alert>
      )}
      {error && (
        <Alert
          severity="error"
          style={{ marginBottom: '20px' }}
          onClose={() => {
            seterror(false);
          }}
        >
          <AlertTitle>Errors while saving changes.</AlertTitle>
          <p>{errorMessage}</p>
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* {mainContext.editedRuns} */}
        <>
          <div className="headline">
            <h2>Run{mainContext.editedRuns.length > 1 && <>s ({mainContext.editedRuns.length})</>}</h2>
            {/*             <p>
              {mainContext.editedRuns.map((item) => (
                <span key={item.id}>
                  {item.id}
                  <br />
                </span>
              ))}
            </p> */}
            <Button variant="outlined" startIcon={<Cloud />} className="classicButton big">
              Download Fit Files
            </Button>
          </div>
          <div className="content">
            {pruffixEditing ? (
              <div className="center">
                <div className="leftSide">
                  <div className="topTwo">
                    <div className="topTwoFirst" />
                    <TextField {...register("prefix")} name="prefix" label="add prefix" placeholder="East Route" control={control} fullWidth />
                  </div>
                </div>
                <div className="rightSide">
                  <TextField {...register("suffix")} name="suffix" label="add suffix" placeholder="- Std Cervelo" control={control} fullWidth />
                </div>
              </div>
            ) : null}
            <div className="center">
              <TextField
                {...register("description")}
                InputLabelProps={{
                  shrink: true
                }}
                label="Description"
                name="description"
                control={control}
                fullWidth
              />
            </div>
            <div className="center">
              <TextField
                {...register("rider_name_run")}
                label="Rider Name"
                InputLabelProps={{
                  shrink: true
                }}
                name="rider_name_run"
                control={control}
                fullWidth
              />
            </div>
            <CalibrationVar
              label="Combined Mass"
              units="kg"
              varName="combined_mass"
              index={editedRun.combined_mass_index}
              dictionary={getDictionary('combined_mass')}
              onUpdate={updateCalibrationParams} onReset={removeCalibrationParams}
            />
            <CalibrationVar
              label="Wheel Circumference"
              units="m"
              varName="wheel_circumference"
              index={editedRun.wheel_circumference_index}
              dictionary={getDictionary('wheel_circumference')}
              onUpdate={updateCalibrationParams} onReset={removeCalibrationParams}
            />
            <CalibrationVar
              label="Power meter slope"
              units=""
              varName="power_meter_slope"
              index={editedRun.power_meter_slope_index}
              dictionary={getDictionary('power_meter_slope')}
              onUpdate={updateCalibrationParams} onReset={removeCalibrationParams}
            />
            <CalibrationVar label="Crr" units="s/m" varName="crr" index={editedRun.crr_index} dictionary={getDictionary('crr')} onUpdate={updateCalibrationParams} onReset={removeCalibrationParams} />

            <CalibrationVar
              label="Cp Correction"
              units=""
              varName="cp_corr"
              index={editedRun.cp_corr_index}
              dictionary={getDictionary('cp_corr')}
              onUpdate={updateCalibrationParams} onReset={removeCalibrationParams}
            />

            <CalibrationVar label="Yaw Correction" units="" varName="yaw_offset" index={editedRun.yaw_offset_index} dictionary={getDictionary('yaw_offset')} onUpdate={updateCalibrationParams} onReset={removeCalibrationParams} />
            <CalibrationVar label="Route" units="" varName="route" index={editedRun.route_index} dictionary={getDictionary('route')} onUpdate={updateCalibrationParams} onReset={removeCalibrationParams} />
            <CalibrationVar label="Gates" units="" varName="gates" index={editedRun.gates_index} dictionary={getDictionary('gates')} onUpdate={updateCalibrationParams} onReset={removeCalibrationParams} />
            <br />
            <div className="line">
              <Button variant="outlined" color="secondary" onClick={resetFormToOriginals}>
                Reset
              </Button>
              <div className="line">
                <Button
                  variant="outlined"
                  startIcon={<Close />}
                  className="classicButton"
                  onClick={ev => {
                    localStorage.setItem('changesInProgress', false);
                    dispatch({ type: 'SET_EDITING_RUNS', payload: [] });
                  }}
                >
                  Discard
                </Button>
                <Button variant="outlined" className="classicButton" startIcon={<Check />} color="primary" type="submit" onClick={() => { }}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </>
      </form>
      <div className="bottom">
        <h2>Member group(s)</h2>
        <GroupTable groupsIDs={groupsIDs} />
      </div>
    </div>
  );
};
export default Runs;
