const TableRow = ({ item, colors }) => {
  //  console.log(item);
  const withRows = item.filter((subitem) => {
    return (
      (subitem.value && Array.isArray(subitem.value)) ? subitem : false
    );
  })
  const numberOfRows = withRows[0].value[0].length - 1;
  var rows = [];
  for (var i = 1; i <= numberOfRows; i++) {
    rows.push(
      <tr key={`trr${i}`}>
        {item.map((subitem, index) => {
          // console.log(subitem);
          return (
            subitem.value && Array.isArray(subitem.value) &&
            subitem.value.map((subitem3, index2) => (
              <td key={`td${index}${index2}`} style={{ backgroundColor: colors[index] }} dangerouslySetInnerHTML={{ __html: subitem3[i] }}></td>
            ))
          );
        })}
      </tr>
    );
  }

  return (
    <>
      <tr>
        {item.map((subitem, index) => {
          //console.log(index);
          return subitem.value && Array.isArray(subitem.value) ? (
            subitem.value.map((subitem3, index2) => (
              <td key={`th${index}${index2}`} style={{ backgroundColor: colors[index] }}>
                <strong dangerouslySetInnerHTML={{ __html: subitem3[0] }}></strong>
              </td>
            ))
          ) : (
            <td key={`th2${index}`} style={{ backgroundColor: colors[index] }} rowSpan={numberOfRows + 1}>
              <strong> {subitem.value}</strong>
            </td>
          );
        })}
      </tr>

      {rows}
    </>
  );
};
export default TableRow;
