const mainReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL_RUNS':
      return {
        ...state,
        allRuns: action.payload,
        needsRerun: false
      };
    case 'SET_ALL_GROUPS':
      return {
        ...state,
        allGroups: action.payload
      };
    case 'SET_ALL_RIDERS':
      return {
        ...state,
        allRiders: action.payload
      };
    case 'SET_CALCS':
      return {
        ...state,
        calCalc: action.payload
      };
    case 'SET_EDITING_RUNS':
      return {
        ...state,
        editedRuns: action.payload
      };
    case 'SET_EDITING_GROUP':
      return {
        ...state,
        editedGroup: action.payload
      };
    case 'SET_DIF_GROUP':
      return {
        ...state,
        editedRunsDifferFromSelectedGroup: action.payload
      };
    case 'SET_PLOT_VARS':
      return {
        ...state,
        plotVars: action.payload
      };
    case 'NEEDS_RERUN':
      return {
        ...state,
        needsRerun: action.payload
      };
    case 'SET_FILTER_VARS':
      return {
        ...state,
        filterVars: action.payload
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};
export default mainReducer;
