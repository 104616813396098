import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import TableRow from '../components/TableRow';
import axios from '../utils/axios';
import MainContext from '../stores/mainContext';
import { Cloud } from '../icons/Cloud';

const Runs = () => {
  //console.log(runTable);
  const { mainContext } = useContext(MainContext);
  const [runTable, setRunTable] = useState(null);
  const [colors, setColors] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.post('/run-data-table/', JSON.stringify(mainContext.editedRuns.map(item => item.id)));
        console.log('response', response);
        setRunTable(response.data);

        let newColors = [];
        response.data.column_headers.forEach((item, index) => {
          newColors.push('item.color', item.color);
        });
        setColors(newColors);
        // console.log('colors', newColors);
      } catch (e) {
        throw e;
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.editedRuns]);

  return (
    <div className="viewRuns">
      <div className="headline">
        <div className="line">
          <h2>View Runs</h2>
          <div className="buttons">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Cloud />}
              className="classicButton"
              onClick={() => {
                const go = async () => {
                  try {
                    const response = await axios.post(`/run-data-file/csv`, JSON.stringify(mainContext.editedRuns.map(item => item.id)));
                    console.log('response', response);
                  } catch (e) {
                    throw e;
                  }
                };
                go();
              }}
            >
              Download CSV
            </Button>
            &nbsp; &nbsp;
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Cloud />}
              className="classicButton"
              onClick={() => {
                const go = async () => {
                  try {
                    const response = await axios.post(`/run-data-file/xls`, JSON.stringify(mainContext.editedRuns.map(item => item.id)));
                    console.log('response', response);
                  } catch (e) {
                    throw e;
                  }
                };
                go();
              }}
            >
              Download XLS
            </Button>{' '}
          </div>
        </div>

        {/*         1) 'Download CSV': POST GET /data/api/v1.1/run-data-file/csv
                  body:(selected ids)
                            ["978422d3-c0dd-44b7-b67a-537289ce45ed", "3a789099-bb29-4e78-b590-07f526675f41"]
           2) "Download Excel": POST GET /data/api/v1.1/run-data-file/xls
                 body:(selected ids)
                            ["978422d3-c0dd-44b7-b67a-537289ce45ed", "3a789099-bb29-4e78-b590-07f526675f41"]
               It is essentially the same call as POST /run-data-table/ but with the file type specified
 */}
      </div>
      {runTable && colors && (
        <div className="dataTableWrap">
          <table className="dataTable">
            <thead>
              <tr>
                {runTable.column_headers.map((item, index) => (
                  <th key={`tha${index}`} colSpan={item.subColumns ? item.subColumns.length : 0} style={{ backgroundColor: item.color }}>
                    {item.name}
                  </th>
                ))}
              </tr>
              <tr>
                {runTable.column_headers.map((item, index) =>
                  item.subColumns === null ? (
                    <th key={`thb${index}`}></th>
                  ) : (
                    item.subColumns !== null &&
                    item.subColumns.map((subitem, subIndex) => (
                      <th key={`thc${subIndex}`} dangerouslySetInnerHTML={{ __html: subitem.name }} style={{ backgroundColor: item.color }}></th>
                    ))
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {runTable.run_data.map((item, index) => (
                <React.Fragment>
                  <TableRow key={`table${index}`} item={item} colors={colors} />
                  <tr>
                    {runTable.column_headers.map((item, index) => (
                      <td key={`thc${index}`} colSpan={item.subColumns ? item.subColumns.length : 0}>&nbsp;</td>
                    ))}
                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default Runs;
