import Amplify, { Auth } from 'aws-amplify';
require('dotenv').config();
console.log(process.env);

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'eu-west-2:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION ? process.env.REACT_APP_COGNITO_REGION : 'eu-west-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'eu-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ? process.env.REACT_APP_COGNITO_USER_POOL_ID : 'eu-west-2_PLEASE_PROVIDE',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID ? process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID : 'PLEASE_PROVIDE',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: null /*{
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //domain: '.yourdomain.com',
      domain: process.env.REACT_APP_AWS_COOKIE_DOMAIN ? process.env.REACT_APP_AWS_COOKIE_DOMAIN : 'code8.link',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'lax',
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false
    }*/,
    // OPTIONAL - customized storage object
    // storage: 'MyStorage',

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      //domain: 'https://www.velosense.com',
      domain: process.env.REACT_APP_AWS_DOMAIN ? process.env.REACT_APP_AWS_DOMAIN : 'https://velosense.code8.link',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://velosense.code8.link',
      redirectSignOut: 'https://velosense.code8.link',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

// You can get the current config object
const currentConfig = Auth.configure();

export default currentConfig;
