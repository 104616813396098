import React, { useEffect, useState, useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Nav from './components/Nav';
import RunList from './components/RunList';
import Runs from './pages/Runs';
import Groups from './pages/Groups';
import ViewRuns from './pages/ViewRuns';
import ViewPlot from './pages/ViewPlot';
import dashboardIcon from './icons/dashboard.svg';
import logo from './icons/logo-velosense.png';
import axios from './utils/axios';
import mainReducer from './stores/mainReducer';
import MainContext from './stores/mainContext';
//import { withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
//import dotenvCra from 'dotenv';
//import Moment from 'react-moment';
import moment from 'moment';
import awsconfig from './aws-config';
// require('dotenv').config();
// console.log(process.env);
// process.env.NODE_ENV = process.env.NODE_ENV || 'development';
// dotenvCra.config();
Amplify.configure(awsconfig);

const SelectFirst = () => {
  return <h2 style={{ marginTop: '3rem' }}>Please select run(s) from the left pane first.</h2>;
};

const App = () => {
  /*
  const refreshAccessJwtToken = async () => {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically

    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = cognitoUser.signInUserSession;
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        const token = session?.refreshToken?.token;
        console.log(session);
        if (token) {
          localStorage.setItem('token', token);
        }
      });
    } catch (e) {
      console.log('Unable to refresh Token', e);
    } 
  };
  */

  const initialState = {
    allRuns: [],
    allGroups: [],
    allRiders: [],
    calCalc: {},
    editedRuns: [],
    editedGroup: null,
    plotVars: { xAxis: '', yAxis: '', dataType: '' },
    filterVars: { startDate: moment().subtract(30, 'days'), endDate: moment(), rider: ['all'] },
    isLoading: true
  };
  const [mainContext, dispatch] = useReducer(mainReducer, initialState);
  const [initiated, setinitiated] = useState(false);
  useEffect(() => {
    const getUser = async () => {
      const CognitoUserSession = await Auth.currentAuthenticatedUser();
      console.log('CognitoUserSession', CognitoUserSession);
      const jwtToken = CognitoUserSession?.signInUserSession?.accessToken?.jwtToken;
      //        const refreshToken = CognitoUserSession?.signInUserSession?.refreshToken?.token;
      localStorage.setItem('token', jwtToken);
      /*
      setInterval(() => {
        refreshAccessJwtToken()
      }, 1000 * 60);
      */
    };
    getUser();
    const getData = async () => {
      try {
        const response = await axios.get('/run/');
        dispatch({ type: 'SET_ALL_RUNS', payload: response.data.runs });
        dispatch({ type: 'SET_ALL_RIDERS', payload: response.data.riders });
        dispatch({ type: 'SET_CALCS', payload: response.data.cal_calc });
        const response2 = await axios.get('/group/');
        dispatch({ type: 'SET_ALL_GROUPS', payload: response2.data });

        const editedRunIdsFromLS = localStorage.getItem('editedRuns');
        const editedRunIds = editedRunIdsFromLS ? (editedRunIdsFromLS.indexOf(',') > -1 ? JSON.parse(editedRunIdsFromLS) : editedRunIdsFromLS) : [];
        const editedRunsFromLS = response.data.runs.filter(item => editedRunIds.includes(item.id));
        dispatch({
          type: 'SET_EDITING_RUNS',
          payload: editedRunsFromLS
        });
        dispatch({ type: 'SET_EDITING_GROUP', payload: JSON.parse(localStorage.getItem('editedGroup')) });
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        setinitiated(true);
      } catch (e) {
        throw e;
      }
    };
    getData();
    localStorage.setItem('changesInProgress', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initiated) {
      localStorage.setItem('editedRuns', JSON.stringify(mainContext.editedRuns.map(item => item.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.editedRuns]);

  useEffect(() => {
    if (initiated) {
      localStorage.setItem('editedGroup', JSON.stringify(mainContext.editedGroup));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContext.editedGroup]);

  return (
    <MainContext.Provider value={{ mainContext, dispatch }}>
      <div className="app">
        <header className="header">
          <div className="left">
            <img src={dashboardIcon} alt="Dashboard icon" />
            <h3>Streamlines Dashboard</h3>
          </div>
          <div className="right">
            <AmplifySignOut />
          </div>
        </header>
        <div className="layout">
          <div className="leftCol">
            <RunList />
            <img className="logo" width="273" height="87" src={logo} alt="Streamlines logo" />
          </div>
          <div className="rightCol">
            <Router>
              <div>
                <Nav />
                <Switch>
                  <Route path="/groups">
                    <Groups />
                  </Route>
                  <Route path="/view-runs">{mainContext.editedRuns.length > 0 ? <ViewRuns /> : <SelectFirst />}</Route>
                  <Route path="/view-plot">{mainContext.editedRuns.length > 0 ? <ViewPlot /> : <SelectFirst />}</Route>
                  <Redirect from="/login" to="/" />
                  {/* <Runs key={new Date().getTime()} editedRuns={[...mainContext.editedRuns]} /> */}
                  <Route path="/">{mainContext.editedRuns.length > 0 ? <Runs /> : <SelectFirst />}</Route>
                </Switch>
              </div>
              <p className="footer">Streamlines {new Date().getFullYear()}</p>
            </Router>
          </div>
        </div>
      </div>
    </MainContext.Provider>
  );
};
export default withAuthenticator(App);
//export default App;
