
import { format, startOfDay } from "date-fns";

const formatDate = (date, type, dateTo = false) => {

  if (typeof date === 'undefined') {
    return '-';
  }
  if (date === null) {
    return '-';
  }

  if (typeof date.getMonth !== 'function') {
    date = new Date(date);
  }

  if (dateTo && typeof dateTo.getMonth !== 'function') {
    dateTo = new Date(dateTo);
  }

  switch (type) {
    /*
    {new Date(rowItem.run_ts).getDate()} 
                    {new Date(rowItem.run_ts).toLocaleString('default', { month: 'short' })} 
                    {new Date(rowItem.run_ts).getFullYear()}&nbsp;
                    {new Date(rowItem.run_ts).getHours()}:{new Date(rowItem.run_ts).getMinutes()}
*/
    default:
    case "withTime":
      return format(date, 'd MMM yyyy H:mm');


  }
}
export default formatDate;
