import axios from 'axios';
import { Auth } from 'aws-amplify';

// copied from another project, currently not used !!!

const instance = axios.create({
  // .. where we make our configurations
  // baseURL: 'http://localhost:5000/data/api/v1.0/run/',
  baseURL: 'https://api-test.velosense.uk/data/api/v1.1/'
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
// instance.defaults.headers.common["Accept"] = "application/json";
instance.defaults.headers.patch['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.delete['Content-Type'] = 'application/json';

instance.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        var accessTokenExpire = session.getAccessToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);

        if (accessTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  Auth.signOut()
                } else {
                  config.headers.Authorization = "Bearer " + data.getAccessToken().getJwtToken();
                  localStorage.setItem('token', data.getAccessToken().getJwtToken());
                  resolve(config);
                }
              });
            });
        } else {
          config.headers.Authorization = "Bearer " + session.getAccessToken().getJwtToken();
          localStorage.setItem('token', session.getAccessToken().getJwtToken());
          resolve(config);
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config);
      });
  });
});

// Add a 401 response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      console.log('401 - Authentication failed. ');
      localStorage.removeItem('token');
      const getUser = async () => {
        const CognitoUserSession = await Auth.currentAuthenticatedUser();
        console.log('CognitoUserSession', CognitoUserSession);
        const jwtToken = CognitoUserSession?.signInUserSession?.accessToken?.jwtToken;
        //        const refreshToken = CognitoUserSession?.signInUserSession?.refreshToken?.token;
        localStorage.setItem('token', jwtToken);
        window.location.href = '/';
      };
      getUser();
    } else if (400 === error?.response?.status) {
      console.log('400 - Bad request. Did authentication failed? ', error);
      localStorage.removeItem('token');
      const getUser = async () => {
        const CognitoUserSession = await Auth.currentAuthenticatedUser();
        console.log('CognitoUserSession', CognitoUserSession);
        const jwtToken = CognitoUserSession?.signInUserSession?.accessToken?.jwtToken;
        //        const refreshToken = CognitoUserSession?.signInUserSession?.refreshToken?.token;
        localStorage.setItem('token', jwtToken);
        window.location.href = '/';
      };
      getUser();
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
