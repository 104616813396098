import React, { useState, useEffect, forwardRef, useRef, useContext } from 'react';
import { Button, Radio } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import MaterialTable, { MTableAction } from 'material-table';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Search } from '../icons/Search';
import { Plus } from '../icons/Plus';
import { Edit } from '../icons/Edit';

import axios from '../utils/axios';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
//import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
//import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MainContext from '../stores/mainContext';

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <div ref={ref}>
      <AddBox {...props} />
    </div>
  )),
  Check: forwardRef((props, ref) => (
    <div ref={ref}>
      <Check {...props} />
    </div>
  )),
  Clear: forwardRef((props, ref) => (
    <div ref={ref}>
      <Clear {...props} />
    </div>
  )),
  Delete: forwardRef((props, ref) => (
    <div ref={ref}>
      <DeleteOutline {...props} />
    </div>
  )),
  DetailPanel: forwardRef((props, ref) => (
    <div ref={ref}>
      <ChevronRight {...props} />
    </div>
  )),
  Edit: forwardRef((props, ref) => (
    <div ref={ref}>
      <Edit {...props} />
    </div>
  )),
  Export: forwardRef((props, ref) => (
    <div ref={ref}>
      <SaveAlt {...props} />
    </div>
  )),
  Filter: forwardRef((props, ref) => (
    <div ref={ref}>
      <FilterList {...props} />
    </div>
  )),
  FirstPage: forwardRef((props, ref) => (
    <div ref={ref}>
      <FirstPage {...props} />
    </div>
  )),
  LastPage: forwardRef((props, ref) => (
    <div ref={ref}>
      <LastPage {...props} />
    </div>
  )),
  NextPage: forwardRef((props, ref) => (
    <div ref={ref}>
      <ChevronRight {...props} />
    </div>
  )),
  PreviousPage: forwardRef((props, ref) => (
    <div ref={ref}>
      <ChevronLeft {...props} />
    </div>
  )),
  ResetSearch: forwardRef((props, ref) => (
    <div ref={ref}>
      <Clear {...props} />
    </div>
  )),
  Search: forwardRef((props, ref) => (
    <div ref={ref}>
      <Search {...props} />
    </div>
  )),
  SortArrow: forwardRef((props, ref) => (
    <div ref={ref}>
      <ArrowDownward {...props} />
    </div>
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <div ref={ref}>
      <Remove {...props} />
    </div>
  )),
  ViewColumn: forwardRef((props, ref) => (
    <div ref={ref}>
      <ViewColumn {...props} />
    </div>
  )),
  Save: forwardRef((props, ref) => (
    <div ref={ref}>
      <SaveAlt {...props} />
    </div>
  ))
};

const Groups = () => {
  const { mainContext, dispatch } = useContext(MainContext);

  /*   const [edit, setEdit] = useState(false);
  const [colorPrimary, setColorPrimary] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  }; */

  const emptyRef = useRef(null);
  const emptyRefSecond = useRef(null);

  useEffect(() => {
    //console.log(emptyRef.current.parentNode);

    if (emptyRef.current !== null) {
      const tableBorder = emptyRef.current.parentNode;
      tableBorder.style.borderBottom = 'none';
    }
    if (emptyRefSecond.current !== null) {
      const tableBorder = emptyRefSecond.current.parentNode;
      tableBorder.style.borderBottom = 'none';
    }
  }, [emptyRef]);

  const addActionRef = useRef();
  const giveFeedback = (isSuccessfull, message) => {
    setLoading(false);
    if (isSuccessfull) {
      setsuccess(true);
      seterror(false);
      setsuccessMessage(message);
    } else {
      seterror(true);
      setsuccess(false);
      seterrorMessage(message);
    }
  };

  const [alertPosition, setalertPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('Well done.');
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState('Unknown error occured.');

  return (
    <>
      <div className="groups"></div>
      <div className="headline">
        <div className="line">
          <h2>Groups</h2>
          <Button variant="outlined" color="primary" startIcon={<Plus />} className="classicButton" onClick={() => addActionRef.current.click()}>
            New Group
          </Button>
        </div>
      </div>
      {loading && alertPosition === 0 && <LinearProgress style={{ marginTop: '20px' }} />}
      {success && alertPosition === 0 && (
        <Alert
          severity="success"
          style={{ marginTop: '20px' }}
          onClose={() => {
            setsuccess(false);
          }}
        >
          <AlertTitle>Success saving changes.</AlertTitle>
          <p>{successMessage}</p>
        </Alert>
      )}
      {error && alertPosition === 0 && (
        <Alert
          severity="error"
          style={{ marginTop: '20px' }}
          onClose={() => {
            seterror(false);
          }}
        >
          <AlertTitle>Errors while saving changes.</AlertTitle>
          <p>{errorMessage}</p>
        </Alert>
      )}

      <div className="center">
        <MaterialTable
          title=""
          columns={[
            {
              title: '',
              field: 'radio',
              cellStyle: { padding: 0, width: 20 },
              headerStyle: { padding: 0, width: 20 },
              render: rowData => {
                return (
                  <input
                    readOnly
                    type="radio"
                    name="groupRadio"
                    checked={mainContext.editedGroup === rowData.id}
                    style={{ zoom: 1.5 }}
                    onClick={ev => {
                      // console.log(rowData.id);
                      if (mainContext.editedGroup !== rowData.id) {
                        // console.log(rowData.id);
                        dispatch({ type: 'SET_EDITING_GROUP', payload: rowData.id });
                      } else {
                        // console.log(null);
                        // dispatch({ type: 'SET_EDITING_GROUP', payload: null });
                      }
                    }}
                  />
                );
              },
              editable: 'never'
            },
            /* { title: 'ID', field: 'id', editable: false }, */
            { title: 'Tag', field: 'group_tag' },
            { title: 'Description', field: 'description' },
            {
              title: 'Count',
              field: 'count',
              editable: 'never',
              render: rowData => {
                let memberCount = mainContext.allRuns.filter(item => item.group.includes(rowData.id));
                return memberCount.length;
              }
            }
          ]}
          data={[...mainContext.allGroups]}
          icons={tableIcons}
          components={{
            Container: props => <div className="table" {...props} />,
            Action: props => {
              if (typeof props.action === typeof Function || props.action.tooltip !== 'Add') {
                return <MTableAction {...props} />;
              } else if (props.action.icon === 'checked') {
                return (
                  <Button onClick={event => props.action.onClick(event, props.data)} color="primary" variant="contained" style={{ textTransform: 'none' }} size="small">
                    My Button
                  </Button>
                );
              } else {
                return <div ref={addActionRef} onClick={props.action.onClick} />;
              }
            }
          }}
          overrides={{ Checkbox: <Radio /> }}
          options={{
            title: false,
            paging: false,
            search: true,
            selectionRadio: true,
            selection: false,
            style: { border: 0, margin: '1rem' },
            addRowPosition: 'first',
            showTextRowsSelected: false,
            rowStyle: rowData => ({
              backgroundColor: rowData.tableData.checked ? '#37b15933' : ''
            }),
            selectionProps: rowData => ({
              color: 'secondary',
              checked: rowData.checked
            }),
            actionsColumnIndex: -1
            /* rowStyle: (rowData) => {
              let selected = selectedRow && selectedRow.tableData.id === rowData.tableData.id;
              return {
                backgroundColor: selected ? "#7f18ab" : "#FFF",
                color: selected ? "#e0dd1f !important" : "#000",
              };
            }, */
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setalertPosition(0);
                const go = async () => {
                  try {
                    setLoading(true);
                    setsuccess(false);
                    const response = await axios.post(`/group/`, {
                      group_tag: newData.group_tag,
                      description: newData.description
                    });
                    console.log(response);
                    const newId = response.data.group_id;
                    const newGroup = { ...newData, id: newId };
                    dispatch({ type: 'SET_ALL_GROUPS', payload: [...mainContext.allGroups, newGroup] });
                    dispatch({ type: 'SET_EDITING_GROUP', payload: newId });

                    giveFeedback(true, 'New group has been created with an id: ' + newId);
                    resolve();
                  } catch (e) {
                    giveFeedback(false, e.toString() === 'Error: Request failed with status code 409' ? 'Group with this description already exists.' : e.toString());
                    resolve();
                    throw e;
                  }
                };
                go();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setalertPosition(0);
                const go = async () => {
                  try {
                    setLoading(true);
                    setsuccess(false);
                    const response = await axios.put(`/group/${newData.id}`, {
                      group_tag: newData.group_tag,
                      description: newData.description
                    });
                    console.log(response);
                    const dataUpdate = [...mainContext.allGroups];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = { ...dataUpdate[index], ...newData };
                    const oldGroups = mainContext.allGroups.filter(item => item.id !== dataUpdate[index].id);
                    const newAllGroups = [...oldGroups, dataUpdate[index]];
                    dispatch({ type: 'SET_ALL_GROUPS', payload: newAllGroups });
                    dispatch({ type: 'NEEDS_RERUN', payload: true });
                    giveFeedback(true, 'Group has been edited.');
                    resolve();
                  } catch (e) {
                    giveFeedback(false, e.toString() === 'Error: Request failed with status code 409' ? 'Group with this description already exists.' : e.toString());
                    resolve();
                    throw e;
                  }
                };
                go();
              })
          }}
          localization={{
            header: {
              actions: ''
            },
            body: {
              emptyDataSourceMessage: (
                <p
                  className="empty"
                  ref={emptyRef}
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    marginTop: '1.5rem',
                    marginBottom: '1.3rem'
                  }}
                >
                  Select a group to edit
                </p>
              )
            }
          }}
        />
      </div>
    </>
  );
};
export default Groups;
